<template>
  <v-container fluid>
    <Header @create-new-role="onCreateRole" />

    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <template>
        <v-row justify="space-between" class="align-center">
          <v-col cols="12" md="auto" class="d-flex align-center">
            <SearchComponent :search="filter.name" @update:search="filter.name = $event"
              :placeholder="$t('placeHolder.searchByName')" />
          </v-col>
        </v-row>

        <Table :headers="filteredHeaders" :items="filteredRoles" itemKey="uid" @edit="onEditRole" @delete="onDeleteRole"
          :clearSelection="clearSelection" @select-row="handleRowClick" @select-item="setselected" />

        <div class="d-flex align-end justify-end">
          <v-btn depressed color="red" class="font-inter text-capitalize white--text mt-2"
            :disabled="!selectedItemsExist || isSelectedItemsContainsMembers" @click="onDeleteRoles">
            {{ $t('testruns.delete') }} {{ getSelectedItemsNoMembersHasData ? `(${getSelectedItemsNoMembersCount})` : ''
            }}
          </v-btn>
        </div>

      </template>

      <DeleteConfirmDialog v-model="showDeleteConfirmDialog" :role="selectedRole" @delete-role="deleteRole"
        @close="handleCloseClick" />

      <ReassignConfirmDialog v-model="showReassignConfirmDialog" :role="selectedRole" @delete-role="deleteRole"
        @close="handleCloseClick" />

    </v-card>
  </v-container>
</template>

<script>
import * as _ from 'lodash'
import { createNamespacedHelpers } from 'vuex';

import makeTagService from '@/services/api/tag';
import makeRoleService from '@/services/api/role';

import Header from '@/components/Admin/Role/Header';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import Table from '@/components/Admin/Role/Table.vue';
import DeleteConfirmDialog from '@/components/Admin/Role/DeleteConfirmDialog.vue';
import ReassignConfirmDialog from '@/components/Admin/Role/ReassignConfirmDialog.vue';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'Roles',

  components: {
    Header,
    SearchComponent,
    Table,
    DeleteConfirmDialog,
    ReassignConfirmDialog
  },

  data() {
    return {
      filter: {
        name: '',
      },
      filteredRoles: [],
      originalRoles: [],
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
          checked: true,
        },
        {
          text: this.$t('description'),
          value: 'description',
          sortable: true,
          checked: true,
        },
        {
          text: this.$t('rolePage.users'),
          value: 'users',
          sortable: true,
          checked: true
        },
        {
          text: '',
          value: 'uid',
          sortable: false,
          class: 'rounded-r-lg',
          width: 60,
          checked: true
        },
      ],
      isLoading: false,
      errorMessage: '',
      showReassignConfirmDialog: false,
      showDeleteConfirmDialog: false,
      clearSelection: false,
      selectedRole: {
        uid: '',
        name: '',
        description: '',
        users: [],
      },
      selectedItems: [],
    }
  },

  computed: {
    ...mapState(['currentAccount']),
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
    filteredHeaders() {
      return this.headers.filter((header) => {
        const filterItem = this.filteredMenuHeaders.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },
    hasRoles() {
      return this.originalRoles.length > 0
    },
    isRoleHasMembers() {
      return this.selectedRole?.members?.length > 0
    },
    getSelectedMembersCount() {
      return this.selectedItems?.length
    },
    selectedItemsExist() {
      return this.selectedItems.length > 0
    },
    getSelectedRoleIds() {
      return this.selectedItems.map(item => item.uid)
    },
    getSelectedItemsNoMembersCount() {
      return this.selectedItems.filter(item => item.members.length === 0).length;
    },
    getSelectedItemsNoMembersHasData() {
      return this.getSelectedItemsNoMembersCount > 0;
    },
    isSelectedItemsContainsMembers() {
      return this.selectedItems.some(item => item.members.length > 0)
    }
  },

  watch: {
    'filter.name': {
      handler: _.debounce(function () {
        this.filterRoles()
      }, 500),
    },
  },

  async mounted() {
    await this.init(this.currentAccount.handle);
  },
  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      next();
    }
  },

  methods: {
    async init(handle) {
      await this.getRoles(handle);
    },
    async getRoles(handle) {
      const roleService = makeRoleService(this.$api);
      try {
        const response = await roleService.getRoles(handle);
        const roles = response.data?.roles;
        
        const roleDetailsPromises = roles.map(async (role) => {
          try {
            const roleDetail = await roleService.getRoleDetails(handle, role.uid);
            return roleDetail.data;
          } catch (error) {
            if (error.response && error.response.status !== 200) {
              return null;
            } 
          }
        });

        const rolesDetails = await Promise.all(roleDetailsPromises);
        this.originalRoles = rolesDetails.filter(role => role !== null);

      } catch (err) {
        this.errorMessage = err.message;
      }

      this.filterRoles();
    },
    async removeRole(handle, roleId) {
      const roleService = makeRoleService(this.$api);
      try {
        await roleService.deleteRole(handle, roleId);
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async removeRoles(handle, roleIds) {
      const roleService = makeRoleService(this.$api);
      try {
        await roleService.deleteRoles(handle, roleIds);
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    handleRowClick(item) {
      this.$router.push({
        name: 'AdminRolesMember',
        params: {
          id: item.uid,
          handle: this.$route.params.handle,
        }
      });
    },
    handleCloseClick() {
      this.clearSelection = true;
    },
    setselected(selectedItems) {
      this.clearSelection = false
      this.selectedItems = selectedItems
    },
    filterRoles() {
      let filteredRoles = _.cloneDeep(this.originalRoles);

      if (this.filter.name) {
        const name = this.filter.name.toLowerCase()
        filteredRoles = filteredRoles.filter(item => item.name.toLowerCase().includes(name))
      }
      this.filteredRoles = filteredRoles
    },

    onCreateRole() {
      this.$router.push({
        name: 'AdminRoleCreate',
        params: {
          handle: this.$route.params.handle,
        }
      });
    },

    async createRole(tag) {

      const tagService = makeTagService(this.$api);
      this.isLoading = true
      try {
        const response = await tagService.createRole(this.currentAccount.handle, tag);
        this.originalRoles.push(response.data)
      } catch (err) {
        this.isLoading = true
        this.errorMessage = err.message;
      }
    },

    onEditRole(role) {
      this.$router.push({
        name: 'AdminRoleEdit',
        params: {
          id: role?.uid,
          handle: this.$route.params.handle,
        }
      });
    },


    onDeleteRole(role) {
      this.selectedRole = role;
      if (role?.members?.length > 0) {
        this.showReassignConfirmDialog = true
      } else {
        this.showDeleteConfirmDialog = true
      }
    },

    async onDeleteRoles() {
      const roleIds = this.getSelectedRoleIds;
      try {
        const roleIds = this.getSelectedRoleIds;
        await this.removeRoles(this.currentAccount.handle, { roleIds });
        await this.init(this.currentAccount.handle);
        this.selectedItems = [];
        await this.$swal({
          title: this.$t('roleSuccessfullyDeleted'),
          icon: 'success',
          showConfirmButton: false,
          position: 'top-end',
          timer: 3000,
          toast: true,
        });
      } catch (error) {
        console.error('An error occurred:', error);
        await this.$swal({
          title: this.$t('errorOccurred'),
          text: error.message,
          icon: 'error',
          showConfirmButton: true,
          position: 'top-end',
          timer: 3000,
          toast: true,
        });
      } finally {
        this.selectedItems = [];
      }
    },

    async deleteRole() {
      try {
        if (this.isRoleHasMembers) {
          this.$router.push({
            name: 'AdminRolesMember',
            params: {
              id: this.selectedRole.uid,
              handle: this.$route.params.handle,
            }
          });
        } else {
          await this.removeRole(this.currentAccount.handle, this.selectedRole.uid);
          this.showDeleteConfirmDialog = false;
          await this.getRoles(this.currentAccount.handle);
        }
      } catch (err) {
        this.errorMessage = err.message;
        console.error('Error deleting role:', err);
      }
    },

  }
}
</script>
